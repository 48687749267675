@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


*{
  font-family: "Ubuntu", sans-serif;
}


.background {
  background-image: url("../src/bk7.jpeg");
  background-size: cover; 
  background-position: center; 
  height: auto;

}

.pac-container{
  width: 50%;
  margin-top: 10px;
}

.pac-container .pac-item {
  white-space: normal;
  word-break: break-word;
  text-align: left;
  font-size: 15px;

  border-radius: 5px;
  font-size: 15px;
}
.pac-icon {
  display: none !important;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
    
  }
  50% {
    opacity: 0;
  }
}